// StoryHighlights.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './StoryDetail.css'; // Import the CSS file
import './StoryHighlights.css'; // Import the CSS file
import RingLoader from 'react-spinners/RingLoader';
import ReactMarkdown from 'react-markdown';

const StoryHighlights = ({ storyId }) => {
  const [storyHighlights, setStoryHighlights] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchStoryHighlights = async () => {
      setLoading(true); // Start loading
      try {
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/build_story_highlights/${storyId}`);
        const highlightsResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/story_highlights/story/${storyId}`);
        const highlightsData = highlightsResponse.data[0] || null;
        setStoryHighlights(highlightsData);
      } catch (error) {
        console.error('Error fetching story highlights:', error);
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchStoryHighlights();
  }, [storyId]);

  if (loading) {
    return (
      <div className="story-highlights">
        <h3 className="section-header">Story Highlights</h3>
        <div className="loading-container">
          <RingLoader color="#ff6600" size={24} />
          <span>Searching for some highlights in the comments...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="story-highlights">
      <h3 className="section-header">Comments Highlights</h3>
      {storyHighlights && storyHighlights.themes && storyHighlights.themes.highlights ? (
        <ul>
          {storyHighlights.themes.highlights.map((highlight, index) => (
            <li key={index} className="highlight-item">
              <div className="highlight-theme">{highlight.highlight}</div>
              <div className="highlight-details">
                <ReactMarkdown>{highlight.description}</ReactMarkdown>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p>No highlights available</p>
      )}
    </div>
  );
};

export default StoryHighlights;