import React, { useEffect, useRef, useState } from 'react';
import ForceGraph3D from '3d-force-graph';
import SpriteText from 'three-spritetext';
import axios from 'axios';
import * as d3 from 'd3'; // Import d3
import * as THREE from 'three'; // Import the entire Three.js library
import './MindMap.css'; // Import the CSS file
import { DotLoader } from 'react-spinners';


const MindMap = ({ storyId }) => {
  const containerRef = useRef();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchKeywords = async () => {
      setLoading(true); // Start loading
      try {
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/build_story_keywords/${storyId}`);
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/story_keywords/story/${storyId}`);
        const keywordsData = response.data[0]?.keywords?.linked_keyword || [];
        setData(keywordsData);
      } catch (error) {
        console.error('Error fetching keywords:', error);
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchKeywords();
  }, [storyId]);

  useEffect(() => {
    if (!data) return;

    const graphData = {
      nodes: [],
      links: []
    };

    const nodeSet = new Set();
    const nodeDegree = {};

    // Create nodes and links
    data.forEach((item) => {
      if (!nodeSet.has(item.keyword)) {
        graphData.nodes.push({ id: item.keyword });
        nodeSet.add(item.keyword);
        nodeDegree[item.keyword] = 0;
      }
      item.linked_keywords.forEach(linkedKeyword => {
        if (!nodeSet.has(linkedKeyword)) {
          graphData.nodes.push({ id: linkedKeyword });
          nodeSet.add(linkedKeyword);
          nodeDegree[linkedKeyword] = 0;
        }
        graphData.links.push({ source: item.keyword, target: linkedKeyword });
        nodeDegree[item.keyword]++;
        nodeDegree[linkedKeyword]++;
      });
    });

    // Initialize 3D force graph
    const Graph = ForceGraph3D()(containerRef.current)
      .graphData(graphData)
      .nodeAutoColorBy('id')
      .nodeThreeObject(node => {
        const sprite = new SpriteText(node.id);
        sprite.material.depthWrite = false; // make sprite background transparent
        sprite.color = darkenColor(node.color);
        sprite.textHeight = 12;
        return sprite;
      })
      .linkColor(() => '#333333') // Set a darker color for the arcs
      .backgroundColor('#eee') // Set the background color here
      .width(window.innerWidth * 0.8) // Set the width dynamically
      .height(500); // Set the height dynamically;
    
    // Calculate adaptive charge strength based on screen width
    const adaptiveStrength = -100 * (window.innerWidth / 800);
    
    // Adjust forces to fit the graph within the container
    Graph.d3Force('charge').strength(adaptiveStrength);
    Graph.d3Force('center', d3.forceCenter(0, 0, 0)); // Center the graph
    Graph.d3Force('link').distance(100); // Adjust link distance

    return () => {
      // Clean up the graph instance
      Graph._destructor();
    };

  }, [data]);

  if (loading) {
    return (
      <div className="mind-map">
       <h3 className="section-header">Comments Mind Map</h3> 
       <div className="loading-container">
          <DotLoader color="#ff6600" size={24} />
          <span>Building a graph of keywords...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="mind-map">
      <h3 className="section-header">Comments Mind Map</h3>
        <div ref={containerRef} className="graph-container"></div>
    </div>
  );
}

function darkenColor(color) {
  // Convert the color to a more saturated/darker version
  // This is a simple example using a fixed darkening factor
  const darkenFactor = 0.5;
  const colorObj = new THREE.Color(color);
  colorObj.multiplyScalar(darkenFactor);
  return `#${colorObj.getHexString()}`;
}
export default MindMap;