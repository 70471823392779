import React, { useEffect } from 'react';
import './Login.css'; // Import the CSS file for styling

const Login = ({ setUser }) => {
  useEffect(() => {

    // Bypass login for localhost
    if (window.location.hostname === 'localhost') {
      const mockUser = { email: 'test@example.com', name: 'Test User' }; // Mock user object
      setUser(mockUser);
      console.log('Bypassed login for localhost');
      return; // Exit early
    }
    loadGoogleScript(() => {
      console.log('Initializing Google Sign-In');
      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleCredentialResponse,
      });

      const signInDiv = document.getElementById('signInDiv');
      if (signInDiv) {
        google.accounts.id.renderButton(
          signInDiv,
          { theme: 'outline', size: 'large', type: 'standard' }
        );
        console.log('Google Sign-In button rendered');
      } else {
        console.error('signInDiv element not found');
      }
    });
  }, []);

  const loadGoogleScript = (callback) => {
    const existingScript = document.getElementById('google-client-script');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.id = 'google-client-script';
      script.async = true;
      script.defer = true;
      script.onload = () => {
        console.log('Google script loaded'); // Add logging
        if (callback) callback();
      };
      script.onerror = () => {
        console.error('Failed to load Google script'); // Add error logging
      };
      document.body.appendChild(script);
    } else {
      console.log('Google script already exists'); // Add logging
      if (callback) callback();
    }
  };

  const handleCredentialResponse = (response) => {
    const userObject = parseJwt(response.credential);
    const email = userObject.email;
    console.log(`User logged in: ${email}`); // Log the email of the user who logged in
    setUser(userObject);
  };

  const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };

  return (
    <div className="login-container">
      <div id="signInDiv" className="login-button"></div>
    </div>
  );
};

export default Login;