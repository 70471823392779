// Commenttldr.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './StoryTLDR.css';
import BounceLoader from 'react-spinners/BounceLoader';
import ReactMarkdown from 'react-markdown';


const StoryTLDR = ({ storyId }) => {

  const [storyTLDR, setStoryTLDR] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log('useEffect triggered');
    const fetchStoryTLDR = async () => {
      setLoading(true); // Start loading
      try {
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/build_story_tldr/${storyId}`);
        const tldrResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/story_tldr/story/${storyId}`);
        const storyTLDR = tldrResponse.data[0] || null;
        // Fetch pre-signed URLs for images
        if (storyTLDR && storyTLDR.tldr) {
            // Directly use the text under .tldr
            setStoryTLDR(storyTLDR);
        }
      } catch (error) {
        console.error('Error fetching story tldr:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchStoryTLDR();
  }, [storyId]);
  
  if (loading) {
    return (
      <div className="comment-tldr">
        <h3 className="section-header">Hacker Comments tl;dr</h3>
        <div className="loading-container">
          <BounceLoader color="#ff6600" size={24} />
          <span>Generating tl;dr...</span>
        </div>
      </div>
    );
  }

  if (!storyTLDR || !storyTLDR.tldr) {
    return null;
  }

  return (
    <div className="comment-tldr">
      <h3 className="section-header">Hacker Comments tl;dr</h3>
      <div className="tldr-container">
        <div className="tldr-content">
          <ReactMarkdown>{storyTLDR.tldr}</ReactMarkdown>
        </div>
      </div>
    </div>
  );
};

export default StoryTLDR;