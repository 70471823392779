import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Login from './Login';
import './index.css';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

const Main = () => {
  const [user, setUser] = useState(null);

  return (
    <React.StrictMode>
      {user ? <App /> : <Login setUser={setUser} />}
    </React.StrictMode>
  );
};

root.render(<Main />);