// src/components/StoryList.js
import React, { useEffect, useRef } from 'react';
import './StoryList.css';
import Pagination from './Pagination'; // Ensure correct import

const StoryList = ({ stories, onSelectStory, currentPage, totalPages, onPageChange, storiesPerPage }) => {

  const listRef = useRef(null);

  const indexOfLastStory = currentPage * storiesPerPage;
  const indexOfFirstStory = indexOfLastStory - storiesPerPage;
  const currentStories = stories.slice(indexOfFirstStory, indexOfLastStory);
  const startNumber = indexOfFirstStory + 1; // Calculate the starting number for the list

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTo({
        top: 0,
        behavior: 'smooth' // Enable smooth scrolling
      });
    }
  }, [currentPage]);

  return (
    <div className="story-list" ref={listRef}>
      <ol start={startNumber}>
        {currentStories.map((story) => (
          <li key={story.id} onClick={() => onSelectStory(story)}>
            <div className='story-title'>{story.title}</div>
            <div className='story-meta'>
              {story.author} | <a href={story.url} target="_blank" rel="noopener noreferrer"> Read Story </a> | <a href={`https://news.ycombinator.com/item?id=${story.hn_id}`} target="_blank" rel="noopener noreferrer">View Comments on HackerNews</a> | Comments: {story.comments_count} | Created: {new Date(story.created_at).toLocaleString()}
            </div>
          </li>
        ))}
      </ol>
      <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />
    </div>
  );
};

export default StoryList;