import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './Acknowledgement.css'; // Import the CSS file for styling

const Acknowledgement = () => {
  return (
    <div>
      <main className="acknowledgement-content">
        <h2>Acknowledgements</h2>
        <p>Thanks to <a href="https://www.ycombinator.com/" target="_blank" rel="noopener noreferrer">Y Combinator</a> for providing the <a href="https://github.com/HackerNews/API" target="_blank" rel="noopener noreferrer">Hacker News API</a>.</p>
        <p>Thanks to all the great comments and discussions on HackerNews writen by all the hackers.</p>
        <p>Thanks to <a href="https://getbootstrap.com/" target="_blank" rel="noopener noreferrer">Bootstrap Icons</a> for all the beautiful icons used in this app.</p>
        <p>Thanks to <a href="https://www.davidhu.io/react-spinners/" target="_blank" rel="noopener noreferrer">REACT Spinners</a> for providing a great open source selection of loading components.</p>
        <p>Thanks to <a href="https://platform.openai.com/" target="_blank" rel="noopener noreferrer">OpenAI</a> for providing great AI models for information summary and image generation.</p>    
        <Link to="/" className="back-link">← Back to Home</Link> {/* Link to the landing page */}
      </main>
    </div>
  );
};

export default Acknowledgement;