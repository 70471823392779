import React from 'react';
import './Pagination.css';

function Pagination({ currentPage, totalPages, onPageChange }) {
  const getPageNumbers = () => {
    const pageNumbers = [];
    const delta = 1;

    const range = {
      start: Math.max(2, currentPage - delta),
      end: Math.min(totalPages - 1, currentPage + delta),
    };

    pageNumbers.push(1);

    if (range.start > 2) {
      pageNumbers.push('...');
    }

    for (let i = range.start; i <= range.end; i++) {
      pageNumbers.push(i);
    }

    if (range.end < totalPages - 1) {
      pageNumbers.push('...');
    }

    if (totalPages > 1) {
      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  const pageNumbers = getPageNumbers();

  return (
    <div className="pagination">
      {currentPage > 1 && (
        <button
          className="page-item"
          onClick={() => onPageChange(currentPage - 1)}
        >
          &laquo;
        </button>
      )}
      {pageNumbers.map((pageNumber, index) =>
        pageNumber === '...' ? (
          <span key={index} className="ellipsis">...</span>
        ) : (
          <button
            key={index}
            className={`page-item ${pageNumber === currentPage ? 'active' : ''}`}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </button>
        )
      )}
      {currentPage < totalPages && (
        <button
          className="page-item"
          onClick={() => onPageChange(currentPage + 1)}
        >
          &raquo;
        </button>
      )}
    </div>
  );
}

export default Pagination;
