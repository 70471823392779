import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import './StoryDetail.css';
import MindMap from './MindMap';
import CommentThemes from './CommentThemes';
import UserPerspectives from './UserPerspectives';
import StoryHighlights from './StoryHighlights';
import StoryTLDR from './StoryTLDR'


function StoryDetail({ story }) {

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [commentsCount, setCommentsCount] = useState(0); // New state variable for comments count
  
  const preprocessUrl = (url) => {
    const urlObj = new URL(url);
    return urlObj.pathname.substring(1); // Remove the leading '/'
  };
  
  const fetchPresignedUrl = async (key) => {
    try {
      console.log(`Fetching pre-signed URL for key: ${key}`); // Add this line to log the key
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/generate-presigned-url?key=${key}`);
      return response.data.url;
    } catch (error) {
      console.error('Error fetching pre-signed URL', error);
      return null;
    }
  };

  useEffect(() => {
    const fetchDetails = async () => {
      setLoading(true); // Reset loading state on new fetch
      try {
        
        const comments_count_url = `${process.env.REACT_APP_API_BASE_URL}/api/comments_count/${story.id}`
        const commentsCountResponse = await axios.get(comments_count_url);
        console.log(`Pinging URL: ${comments_count_url}`);
        setCommentsCount(commentsCountResponse.data.comments_count);
        if (commentsCountResponse && commentsCountResponse.data) {
            console.log(`Found comments: ${commentsCountResponse.data.comments_count}`);
        } else {
            console.log('commentsCountResponse or commentsCountResponse.data is undefined or null');
        }
        console.log('After fetching comments count');
        if (commentsCountResponse.data.comments_count < 5) {
          setLoading(false);
          return;
        }

        setLoading(false);

      } catch (error) {
        console.error('Error fetching details:', error);
        setError(error);
        setLoading(false);
      }
    };

    fetchDetails();
  }, [story]);

  if (loading) return <ClipLoader size={50} color={"#123abc"} loading={loading} />; // Show spinner while loading
  if (error) return <div>Error loading details: {error.message}</div>;
  if (commentsCount < 5) return <div>Not enough comments for AI to generate summary.</div>; // Show message if comments count is less than 5
  
  return (
    <div className="story-detail">

      <div className="story-detail-header">
        <span className="story-detail-header-title">{story.title}</span>
        <span className="story-detail-header-url"> {commentsCount} comments ({new URL(story.url).hostname})</span>
      </div>
      <hr className="segment-line" /> {/* Segment line */}
      <StoryTLDR storyId={story.id} />
      <div className="mind-map-section">
        <MindMap storyId={story.id} />
      </div>
      <hr className="segment-line" /> {/* Segment line */}
      <StoryHighlights storyId={story.id} />
      <hr className="segment-line" /> {/* Segment line */}
      <UserPerspectives storyId={story.id} />

    </div>
);
}

export default StoryDetail;
