import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import axios from 'axios';
import Header from './components/Header';
import StoryList from './components/StoryList';
import StoryDetail from './components/StoryDetail';
import About from './components/About';
import Acknowledgement from './components/Acknowledgement';
import './App.css';

function App() {
  const [stories, setStories] = useState([]);
  const [selectedStory, setSelectedStory] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [dividerPosition, setDividerPosition] = useState(0);
  const storyListRef = useRef(null);
  const storiesPerPage = 20; // Adjust this number as needed

  useEffect(() => {
    const fetchStories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/stories-with-comment-count/`);
        setStories(response.data);
      } catch (error) {
        console.error('Error fetching stories:', error);
      }
    };

    fetchStories();
  }, []);

  const totalPages = Math.ceil(stories.length / storiesPerPage);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startDrag = (e) => {
    e.preventDefault();
    document.addEventListener('mousemove', onDrag);
    document.addEventListener('mouseup', stopDrag);

    e.target.addEventListener('dblclick', resetDividerPosition);
  };

  const resetDividerPosition = () => {
    setDividerPosition(70);
    document.documentElement.style.setProperty('--dividerPosition', '30%');
  };

  const onDrag = (e) => {
    const newDividerPosition = ((window.innerWidth - e.clientX) / window.innerWidth) * 100;
    if (newDividerPosition >= 0 && newDividerPosition <= 100) {
      setDividerPosition(newDividerPosition);
    }
  };

  const stopDrag = () => {
    document.removeEventListener('mousemove', onDrag);
    document.removeEventListener('mouseup', stopDrag);
    
    // Remove the double-click event listener after dragging stops
    document.removeEventListener('dblclick', resetDividerPosition);
  };

  const closeStoryDetail = () => {
    setSelectedStory(null);
    setDividerPosition(0); // Reset divider position when closing story
    console.log('Story detail closed');
    console.log('Divider Position set to 100 on story close');
  };

  const onSelectStory = (story) => {
    setSelectedStory(story);
    const newDividerPosition = window.innerWidth > 901 ? 70 : 100;
    setDividerPosition(newDividerPosition); // Set divider position to 0 when a story is selected
    console.log('Story selected:', story);
    console.log('Divider Position set to 80 on story selection');
  };

  useEffect(() => {
    const paddingValue = dividerPosition === 100 ? '0px' : '20px';
    document.documentElement.style.setProperty('--story-list-padding', paddingValue);
    console.log('Divider Position updated:', dividerPosition);
    console.log('Padding set to:', paddingValue);
  }, [dividerPosition]);

  return (
    <Router>
      <Header /> {/* Ensure the Header is always displayed */}
      <Routes> {/* Use Routes instead of Switch */}
        <Route path="/" element={
          <div className={`app ${selectedStory ? 'story-selected' : ''}`}>
            <div className="content">
              <div 
                className="story-list" 
                style={{width: selectedStory ? `calc(100% - ${dividerPosition}%)` : '100%'}}
              >
                {stories.length === 0 ? (
                  <p>Loading stories...</p>
                ) : (
                  <StoryList
                    stories={stories}
                    onSelectStory={onSelectStory}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                    storiesPerPage={storiesPerPage}
                  />
                )}
              </div>
              {selectedStory && (
                <>
                  <div
                    className="divider"
                    onMouseDown={startDrag}
                    style={{ left: `calc(100% - ${dividerPosition}%)` }}
                  />
                  <div
                    className="story-detail"
                    style={{ width: `${dividerPosition}%`, position: 'relative' }}
                  >
                    <div className="button-area">
                      <div className="action-buttons">
                        <a href={selectedStory.url} target="_blank" rel="noopener noreferrer">
                          <button className="read-story-button">Read Story</button>
                        </a>
                        <a href={`https://news.ycombinator.com/item?id=${selectedStory.hn_id}`} target="_blank" rel="noopener noreferrer">
                          <button className="read-comments-button">Read Comments</button>
                        </a>
                      </div>
                      <button className="close-button" onClick={closeStoryDetail}>
                        ✖
                        <span className="slide-text">Close Comments Summary Tab</span>
                      </button>
                    </div>
                    <StoryDetail story={selectedStory} />
                  </div>
                </>
              )}
            </div>
          </div>
        } />
        <Route path="/About" element={<About />} />
        <Route path="/Acknowledgement" element={<Acknowledgement />} />
      </Routes>
    </Router>
  );
}

export default App;