import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './About.css';

const About = () => {
  return (
    <div>
      <main className="about-content">
        <h2>About</h2>
        <p>Welcome to HackerComments, a personal hobby project aimed at enhancing viewing discussions on HackerNews. This site is non-profit and fueled by the love for AI and tech community.</p>
        <p>Hacker News is a platform for sharing and discussing stories in tech and entrepreneurship. It features a depth-first comment display, which can sometimes limit readability.</p>
        <p>Our goal is to use AI to summarize and organize comments, helping users quickly grasp diverse opinions and navigate discussions more easily.</p>
        <p>We hope this tool enriches your experience with HackerNews discussions.</p>
        <Link to="/" className="back-link">← Back to Home</Link> {/* Link to the landing page */}
      </main>
    </div>
  );
};

export default About;